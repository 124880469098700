import React, { useState,useEffect} from 'react';
import { Layout, SEO } from '@/components/index'
import useNavigate from '@/hooks/useNavigate'

const Index = () => {

    useEffect(()=>{
        useNavigate('/')
    },[])
    return <>
        <Layout menuBlack={true} >
        
            
        </Layout>
    </>
}
export default Index